import React from "react";
import ReactDOM from "react-dom";

import "js/preload";

import { fontManager } from "js/core/services/fonts";

import MainView from "./react/MainView";
import Presenter from "js/react/views/Player/Presenter";

// Preloading Source Sans font (body font)
fontManager.loadFont("sourcesanspro")
    // Loading all styles as css fonts
    .then(font => Promise.all(font.styles.map(({ loadCssFont }) => loadCssFont())))
    .then(() => {
        // Initializing react
        const root = document.getElementById("react-root");
        // Ensure the root isn't collapsed
        root.style.height = "100vh";
        ReactDOM.render(window.isPresenter ? <Presenter /> : <MainView />, root);
    })
    .catch(err => console.error(err));

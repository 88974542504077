import React from "react";
import styled, { keyframes } from "styled-components";

import { loadImage } from "js/core/utilities/promiseHelper";
import { getStaticUrl } from "js/config";

import AppUpdater from "./AppUpdater";
import Player, { PlayerContextType } from "js/react/views/Player/Player";

const ControlsContainer = styled.div`
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
    background: black;
`;

const fadeIn = keyframes`
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
`;

const LoadPresentationButton = styled.div.attrs(({ disabled }) => ({
    style: {
        pointerEvents: disabled ? "none" : "auto"
    }
}))`
    user-select: none;
    text-transform: uppercase;
    background-color: #11a9e2;
    padding: 15px 20px;
    color: white;
    border-radius: 2px;
    cursor: pointer;
    letter-spacing: 0.5px;
    opacity: 0;
    animation: ${fadeIn} 500ms 1.5s forwards;
    position: relative;
    top: -50px;

    &:hover {
        box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
        background-color: rgb(11, 118, 158);
    }
`;

const ErrorMessageContainer = styled.span`
    user-select: none;
    color: red;

    a {
        text-decoration: underline;
        cursor: pointer;
    }
`;

const scaleIn = keyframes`
    0% {
        transform: scale(10);
    }
    50% {
        transform: scale(1);
    }
    75% {
        transform: scale(1.3);
    } 
    100% {
        transform: scale(1);
    }  
`;

const Bubble = styled.div`
    background: #11a9e2;
    width: 202px;
    height: 202px;
    border-radius: 50%;
    position: absolute;
    animation: ${scaleIn} 400ms ease forwards;
`;

const splashImageUrl = getStaticUrl("/images/splash-branded.gif");

class MainView extends React.Component {
    constructor() {
        super();

        this.state = {
            hasData: false,
            errorMesssage: null,
            fetchingData: false,
            splashLoaded: false,
            playSplash: false
        };
    }

    componentDidMount() {
        window.loadPresentationData = this.loadPresentationData;

        loadImage(splashImageUrl)
            .then(() => {
                this.setState({ splashLoaded: true });

                setTimeout(() => {
                    this.setState({ playSplash: true });
                }, 510);
            })
            .catch(err => console.error(err));
    }

    loadPresentationData = () => {
        this.setState({
            fetchingData: true,
            hasData: false
        });

        // Calling the main process to load presentation data by emitting the load-presentation-data event
        new Promise((resolve, reject) => {
            // Subsribing to the reply event because the saving process is asynchronous
            document.addEventListener("load-presentation-data-reply", ({ detail }) => {
                const {
                    error,
                    loaded,
                    canceled,
                } = detail;
                if (error) {
                    reject(error);
                    return;
                }

                resolve({ loaded, canceled });
            });

            // Emitting load-presentation-data event
            document.dispatchEvent(new CustomEvent("load-presentation-data"));
        })
            .then(({ loaded }) => this.setState({
                hasData: loaded,
                errorMesssage: null,
                fetchingData: false,
            }))
            .catch(err => {
                console.error(err);

                const errorMesssage = err.message === "Update required"
                    ? <span>Please <a onClick={window.checkForAppUpdate}>update the app</a> to play this presentation</span>
                    : <span>Data is malformed, try another file</span>;

                this.setState({
                    hasData: false,
                    errorMesssage,
                    fetchingData: false
                });
            });
    }

    onPlayerExit = () => {
        this.setState({ hasData: false });
    }

    render() {
        const {
            hasData,
            errorMesssage,
            splashLoaded,
            playSplash,
            fetchingData
        } = this.state;

        return (
            <>
                {hasData &&
                    <Player
                        contextType={PlayerContextType.OFFLINE}
                        onExit={this.onPlayerExit}
                        openPresenterOnPlaybackStarted={window.electronDisplaysCount > 1}
                        enterFullscreenOnPlaybackStarted={true}
                    />
                }
                {!hasData &&
                    <ControlsContainer>
                        {splashLoaded &&
                            <>
                                {!playSplash && <Bubble />}
                                {playSplash && <img style={{ width: 250 }} src={splashImageUrl} />}
                                {playSplash &&
                                    <LoadPresentationButton
                                        onClick={this.loadPresentationData}
                                        disabled={fetchingData}
                                    >
                                        Open presentation...
                                    </LoadPresentationButton>
                                }
                                {errorMesssage && <ErrorMessageContainer>{errorMesssage}</ErrorMessageContainer>}
                            </>
                        }
                    </ControlsContainer>
                }
                <AppUpdater />
            </>
        );
    }
}

export default MainView;

function padZeros(number, length) {
    let string = `${number}`;
    while (string.length < length) {
        string = `0${string}`;
    }
    return string;
}

export function formatDuration(durationSeconds) {
    let seconds = durationSeconds;
    const hours = Math.floor(seconds / (60 * 60));
    seconds -= hours * 60 * 60;
    const minutes = Math.floor(seconds / 60);
    seconds -= minutes * 60;
    return `${padZeros(hours, 2)}:${padZeros(minutes, 2)}:${padZeros(seconds.toFixed(0), 2)}`;
}

import React from "react";
import styled from "styled-components";

import { CircularProgress } from "@material-ui/core";

import {
    SystemUpdateAlt as SystemUpdateAltIcon,
    Close as CloseIcon
} from "@material-ui/icons";

const UpdateDialogContainer = styled.div.attrs(({ visible }) => ({
    style: {
        opacity: visible ? 1 : 0,
        pointerEvents: visible ? "auto" : "none"
    }
}))`
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
    background-color: #222222c2;
    backdrop-filter: blur(10px) grayscale(1);
    z-index: 9999999;
    transition: opacity ease-in-out 0.5s;
`;

const MessageText = styled.div`
    color: #ffffff;
    font-size: 14px;
    letter-spacing: 1px;
    text-transform: uppercase;
`;

const MessageSubtext = styled.div`
    color: #ccc;
    font-size: 12px;
    letter-spacing: 1px;
    margin-top: 10px;
`;

const ButtonsContainer = styled.div`
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
`;

const VerticalButtonContainer = styled.div.attrs(({ style, disabled }) => ({
    style: {
        ...style,
        pointerEvents: disabled ? "none" : "default",
        cursor: disabled ? "default" : "pointer",
        opacity: disabled ? 0.9 : 1
    }
}))`
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    cursor: pointer;
    text-transform: uppercase;

    span {
        font-size: 12px;
        margin-top: 10px;
    }

    svg {
        font-size: 18px;
    }

    &:hover {
        color: #11a9e2;
    }
`;

function VerticalButton({ onClick, Icon, text, style = {}, fetching }) {
    return (
        <VerticalButtonContainer onClick={onClick} style={style} disabled={fetching}>
            {fetching && <CircularProgress size={18} />}
            {!fetching && <Icon />}
            <span>{text}</span>
        </VerticalButtonContainer>
    );
}

const updateEvents = [
    "checking-for-update",
    "update-available",
    "update-not-available",
    "update-downloaded",
    "download-progress",
    "update-error"
];

class AppUpdater extends React.Component {
    constructor() {
        super();

        this.state = {
            updateState: "checking-for-update",
            updateStateData: null,
            showDialogOnlyWhenUpdateAvailable: false,
            isUpdateRejected: false
        };
    }

    componentDidMount() {
        updateEvents.forEach(eventType => document.addEventListener(eventType, this.onAppUpdateEvent));

        this.checkForAppUpdate(true);

        window.checkForAppUpdate = () => this.checkForAppUpdate(false);
    }

    componentWillUnmount() {
        updateEvents.forEach(eventType => document.removeEventListener(eventType, this.onAppUpdateEvent));
    }

    onAppUpdateEvent = ({ type: updateState, detail: updateStateData }) => {
        // There's a bug in the updater, so we have to ignore this error because it means nothing
        if (updateState === "update-error" && updateStateData.message.startsWith("The command is disabled and cannot be executed")) {
            return;
        }

        this.setState({ updateState, updateStateData, disableActionButton: false });

        if (updateState === "update-error") {
            console.error(updateState, updateStateData);
        }
    }

    checkForAppUpdate = showDialogOnlyWhenUpdateAvailable => {
        this.setState({ showDialogOnlyWhenUpdateAvailable, isUpdateRejected: false }, () => {
            document.dispatchEvent(new CustomEvent("check-for-update"));
        });
    }

    downloadUpdate = () => {
        document.dispatchEvent(new CustomEvent("download-update"));
        this.setState({ disableActionButton: true });
    }

    installUpdate = () => {
        document.dispatchEvent(new CustomEvent("install-update"));
        this.setState({ disableActionButton: true });
    }

    rejectUpdate = () => {
        this.setState({ isUpdateRejected: true });
    }

    render() {
        const { updateState, updateStateData, showDialogOnlyWhenUpdateAvailable, isUpdateRejected, disableActionButton } = this.state;

        const isUpdateDialogVisible = !isUpdateRejected &&
            ((showDialogOnlyWhenUpdateAvailable && !["checking-for-update", "update-not-available"].includes(updateState)) || !showDialogOnlyWhenUpdateAvailable);

        return (
            <UpdateDialogContainer visible={isUpdateDialogVisible}>
                {updateState === "checking-for-update" && <>
                    <MessageText>Checking for updates...</MessageText>
                </>}
                {updateState === "update-available" && <>
                    <MessageText>New version available</MessageText>
                    <MessageSubtext>v{updateStateData.version}</MessageSubtext>
                </>}
                {updateState === "update-not-available" && <>
                    <MessageText>You're up to date</MessageText>
                </>}
                {updateState === "download-progress" && <>
                    <MessageText>Downloading update...</MessageText>
                    <MessageSubtext>{updateStateData.percent.toFixed(0)}%</MessageSubtext>
                </>}
                {updateState === "update-downloaded" && <>
                    <MessageText>Ready to install</MessageText>
                </>}
                {updateState === "update-error" && <>
                    <MessageText>Error updating app</MessageText>
                    <MessageSubtext>{updateStateData.message}</MessageSubtext>
                </>}
                <ButtonsContainer>
                    {updateState === "update-available" && <VerticalButton onClick={this.downloadUpdate} Icon={SystemUpdateAltIcon} text="Download" style={{ marginRight: "40px" }} fetching={disableActionButton} />}
                    {updateState === "update-downloaded" && <VerticalButton onClick={this.installUpdate} Icon={SystemUpdateAltIcon} text="Install" style={{ marginRight: "40px" }} fetching={disableActionButton} />}
                    <VerticalButton onClick={this.rejectUpdate} Icon={CloseIcon} text="Close" />
                </ButtonsContainer>
            </UpdateDialogContainer>
        );
    }
}

export default AppUpdater;
